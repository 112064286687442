import React from 'react';

import { ArrowLinkButtonProps } from 'Elements/Button/Arrow';

import Grid, { Col } from 'Components/Grid';

import {
    Wrapper,
    Container,
    Inner,
    Heading,
    Text,
    StyledArrowLinkButton,
    ImageWrapper,
    StyledNextImage,
    Wave
} from './TefAward.styled';

export interface Props {
    heading: string;
    text: string;
    link?: ArrowLinkButtonProps;
    image: {
        src: string;
        alt?: string;
    };
    showBackground?: boolean;
}

const TefAward: React.FC<Props> = props => {
    const { heading, text, link, image, showBackground = false } = props;

    return (
        <Wrapper $showBackground={showBackground}>
            <Grid>
                <Col>
                    <Container>
                        <Inner>
                            <Heading>{heading}</Heading>
                            <Text>{text}</Text>
                            {link && <StyledArrowLinkButton {...link} variant="tertiary" />}
                        </Inner>
                        <ImageWrapper>
                            <StyledNextImage
                                alt="TEF 2023 gold award"
                                {...image}
                                width={172}
                                height={236}
                            />
                        </ImageWrapper>
                        <Wave />
                    </Container>
                </Col>
            </Grid>
        </Wrapper>
    );
};

export default TefAward;
