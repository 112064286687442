import styled, { css } from 'styled-components';
import NextImage from 'next/image';

import ArrowLinkButton from 'Elements/Button/Arrow';

import { ReactComponent as WaveSVG } from 'Public/graphics/waves/promo-point/tef-award/wave-tef-award.svg';

const tefGoldColour = '#FFC11B';

export const Wrapper = styled.div<{ $showBackground: boolean }>`
    ${({ theme, $showBackground }) => css`
        position: relative;
        overflow: hidden;

        ${$showBackground &&
        css`
            &:before {
                content: '';
                position: absolute;
                bottom: 0;
                background-color: ${theme.colors.secondary.tints[1].hex};
                width: 100%;
                height: 6rem;
                z-index: 0;
                padding-bottom: 1.5rem;

                @media ${theme.breakpoints.large.media} {
                    height: 10rem;
                    padding-bottom: 3rem;
                }
            }
        `}
    `}
`;

export const Container = styled.div`
    ${({ theme }) => css`
        position: relative;
        display: flex;
        padding: 0.5rem 1rem 1rem;
        background: ${theme.colors.primaryContrast.hex};
        border: 0.1875rem solid ${tefGoldColour};
        border-radius: 0.5rem;
        overflow: hidden;

        @media ${theme.breakpoints.medium.media} {
            padding: 1rem 1.5rem 1.5rem;
        }

        @media ${theme.breakpoints.large.media} {
            padding: 2rem 3rem 3rem;
        }
    `}
`;

export const Inner = styled.div`
    ${({ theme }) => css`
        max-width: 65%;
        z-index: 1;

        @media ${theme.breakpoints.large.media} {
            max-width: 50rem;
        }
    `}
`;

export const Heading = styled.h2`
    margin: 0;
`;

export const Text = styled.p`
    ${({ theme }) => css`
        margin: 0.5rem 0 0;
        line-height: 1.35;

        @media ${theme.breakpoints.large.media} {
            margin: 1rem 0 0;
            font-size: 1.5rem;
            max-width: none;
        }
    `}
`;

export const StyledArrowLinkButton = styled(ArrowLinkButton)`
    ${({ theme }) => css`
        margin-top: 1rem;

        @media ${theme.breakpoints.large.media} {
            margin-top: 1.5rem;
        }
    `}
`;

export const ImageWrapper = styled.div`
    ${({ theme }) => css`
        display: grid;
        place-items: start center;
        z-index: 1;
        margin-left: 1.25rem;
        margin-top: 0.5rem;
        flex-grow: 1;

        @media ${theme.breakpoints.medium.media} {
            margin-left: 2rem;
            margin-top: 1rem;
        }

        @media ${theme.breakpoints.large.media} {
            margin-top: 0;
        }
    `}
`;

export const StyledNextImage = styled(NextImage)`
    ${({ theme }) => css`
        height: auto;
        max-width: 4.875rem;

        @media ${theme.breakpoints.medium.media} {
            max-width: 7.75rem;
        }

        @media ${theme.breakpoints.large.media} {
            max-width: 10.75rem;
        }
    `}
`;

export const Wave = styled(WaveSVG)`
    ${({ theme }) => css`
        position: absolute;
        top: -5rem;
        right: -31rem;
        width: 60rem;
        height: 46rem;
        transform: scaleX(-1) rotate(235deg);

        @media only screen and (min-width: 24rem) {
            right: -29rem;
        }

        @media ${theme.breakpoints.medium.media} {
            right: unset;
            top: -13rem;
            left: 4rem;
            transform: matrix(0.72, -0.69, 0.69, 0.72, 0, 0);
        }

        @media ${theme.breakpoints.large.media} {
            top: -19rem;
            left: 4rem;
            width: 89.5rem;
            height: 66.5rem;
        }
    `}
`;
